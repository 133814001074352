<script>
import DetailBoxBike from '@/components/DetailBoxBike'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'pozicovna-bicyklov',
  components: {
    DetailBoxBike,
    AppFooter
  },
  data () {
      return {
        defaultPricelist: [['Trekingový bicykel (Cross)', 12, 10], ['Horský bicykel (MTB)', 15, 12], ['Celoodpružený horský bicykel (MTB)', 25, 20]]
    }
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[n].$refs["modal"].open()
    }
  }
};
</script>

<template>
  <div class="pozicovna-view">
    <article>
      <div class="content-wrapper">
        <h1>Požičovňa bicyklov</h1>
        
        <p>Ski Niňaj ponúka služby požičovne bicyklov v 3 prevádzkach. Naplánujte si výlet na Liptov a zažite dobrodružstvá v prírode alebo na cestách medzi liptovskými dedinkami a mestami. Pre celú Vašu rodinu máme bicykle pripravené na jazdenie a tiež množstvo doplnkov.
          Zakladáme si na maximálnej spokojnosti zákazníkov, preto máme v ponuke výber renomovaných značiek v rôznych modeloch a veľkostiach:</p>
        <div class="logo-boxes">
          <div class="logo-box">
            <span>bicykle</span>
            <img src="@/assets/brands/ctm.svg" alt="CTM">
            <img src="@/assets/brands/kellys.svg" alt="Kellys">
            <img src="/images/logo/alpina.png" alt="Alpina">
            <img src="/images/logo/leader-fox.jpg" alt="Leader Fox">
          </div>
          <div class="logo-box">
            <span>kolobežky</span>
            <img src="/images/logo/crussis.png" alt="Crussis">
            <img src="/images/logo/hugo-bike.png" alt="Hugo Bike">
          </div>
        </div>

        <h2>Vyberte si z našej ponuky horských (MTB) a trekových bicyklov</h2>
      </div>

      <div class="boxes">
        <detail-box-bike title="CTM Twister" v-bind:pricelist="defaultPricelist" 
                    class="box" image="bike/ctm-twister.jpg" bg="#97c131" ref="1">
          <p>Športovo-rekreačne ladený trekingový bicykel slovenskej značky CTM.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="bike/ctm-twister.jpg" alt="CTM Twister" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="CTM Stefi" v-bind:pricelist="defaultPricelist" image="bike/ctm-stefi.jpg" class="box" bg="#f71735" ref="2">
          <p>Dámsky trekingový bicykel vhodný na rekreačné cyklovýlety po Liptove. Od overeného slovenského výrobcu CTM.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="bike/ctm-stefi.jpg" alt="CTM Stefi" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="KELLYS Madman 50" v-bind:pricelist="defaultPricelist" class="box" image="bike/kellys-madman-50.jpg" bg="#009eac" ref="3">
          <p>Horský bicykel pre plné nasadenie v teréne s 29" ráfikmi, kvalita so značkou Kellys.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="bike/kellys-madman-50.jpg" alt="KELLYS Madman 50" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="KELLYS Madman 30" v-bind:pricelist="defaultPricelist" class="box" image="bike/kellys-madman-30.jpg" bg="#ff7d3d" ref="4">
          <p>Horský bicykel do terénu s 26" kolesami od Kellys.</p>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
          <template slot="image">
            <Imgix src="bike/kellys-madman-30.jpg" alt="KELLYS Madman 30" />
          </template>
        </detail-box-bike>

        <detail-box-bike title="ALPINA Eco 10 LM" v-bind:pricelist="defaultPricelist" class="box" image="bike/alpina-eco.jpg" bg="#47b9a3" ref="5">
          <p>Dámsky horský bicykel s odpruženou vidlicou a hliníkovým rámom pre pohodlné brázdenie lesnými traťami. Kvalita od výrobcu Kellys.</p>
          <template slot="image">
            <Imgix src="bike/alpina-eco.jpg" alt="ALPINA Eco 10 LM" />
          </template>
          <template slot="popis">
            <p>V cene požičania je helma, zámok, servisná brašňa - taška a voliteľná detská sedačka. Ostatné podľa platného cenníka.</p>
          </template>
        </detail-box-bike>

        <detail-box-bike title="Doplnky a vybavenie" v-bind:pricelist="[]" class="box" image="bike/accessories.jpg" bg="#bbce44" ref="7">
          <p>Predaj a požičanie cyklovýbavy a doplnkov</p>
          <template slot="image">
            <Imgix src="bike/accessories.jpg" alt="Doplnky a vybavenie" />
          </template>
          <template slot="popis">
            <p><b>Predaj výbavy</b><br>
                pumpa, lepenie, multikľúč, prilba, rukavice, dres + cyklokraťasy, pedále, fľaša na pitie, brašnička, montpáky, okuliare<br>
                <b>Požičanie výbavy</b><br>
                cyklo vozík, sedačky pre deti (len s požičaním bicyklov od nás)</p>
          </template>
        </detail-box-bike>
      </div>

      <div class="full-box bg-ebikepoint">
        <div class="full-box-content">
          <h3 style="color: #52b04c">Služby pre vás</h3>
          <p><font-awesome-icon icon="check-circle" /> pojazdná požičovňa - bicykle / kolobežky dovezieme priamo k Vám (min. 4 ks - 12 &euro; / ks)</p>
          <p><font-awesome-icon icon="check-circle" /> profesionálny servis bicyklov, elektrobicyklov a kolobežiek</p>
          <p><font-awesome-icon icon="check-circle" /> detská sedačka grátis pri požičaní bicykla u nás, možnosť požičania cyklovozíka</p>
          <p><font-awesome-icon icon="check-circle" /> zabezpečenie sprievodcu (po dohode na čísle <a href="callto:+4210905357133" title="Zavolaje">0905 357 133</a>)</p>
        </div>
      </div>

      <div class="full-box content-right bg-cyklotrasy">
        <div class="full-box-content">
          <h3 style="color: #96d400">Cyklochodníky a trasy na Liptove</h3>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /><a href="https://cyklotrasy.biker.sk/lipt-mikulas-maluzina-bobrovec-ziar/" title="Liptovský Mikuláš na Malužinú, Bobrovec a Žiar">Liptovský Mikuláš na Malužinú, Bobrovec a Žiar</a> a iné na <a href="https://cyklotrasy.biker.sk/kraj/zilinsky-kraj/" target="_blank" title="">cyklotrasy.biker.sk</a></p>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /> <a href="https://www.mtbiker.sk/vyjazdy/639627/bicykel-mtb-po-liptove.html" target="_blank" title="">MTB po Liptove</a> a ďalšie tipy na <a href="https://mtbiker.sk" target="_blank" title="">mtbiker.sk</a></p>
          <p><font-awesome-icon icon="check" style="margin-right: .5em" /> <a href="https://www.visitliptov.sk/trasa/velky-okruh-okolo-liptovskej-mary/" target="_blank" title="">Veľký okruh okolo Liptovskej Mary</a>, viac na <a href="https://visitliptov.sk" target="_blank" title="">visitliptov.sk</a></p>
        </div>
      </div>

      <div class="full-box bg-prevadzka">
        <div class="full-box-content full-box-content-dark">
          <h3>Kde nás nájdete</h3>
          <p><font-awesome-icon icon="map-marker-alt" /> v Demänovej - Tatra Line Resort Demänová, vedľa čerpacej stanice OMV<br>
             <font-awesome-icon icon="map-marker-alt" /> v Liptovskom Jáne - v Hoteli Ďumbier<br>
             <font-awesome-icon icon="map-marker-alt" /> v Liptovskom Trnovci - na parkovisku pri Liptovskej Mare oproti penziónu Ravence</p>
          <br>
          <p><font-awesome-icon icon="phone-alt" /> +421 905 357 133</p>
          <br>
          <p><router-link to="/kontakt" class="button">Viac</router-link></p>
        </div>
      </div>
    </article>
    <app-footer>
      <b>Požičovňa lyžiarskej a snowboardovej výstroje:</b>
        <ul>
          <li><a @click="openModal('1')">MTB bicykle</a></li>
          <li><a @click="openModal('2')">Trekingové bicykle</a></li>
          <li><a @click="openModal('7')">Predaj a požičanie cyklovýbavy a doplnkov</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #25b430;

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .boxes {
    .box {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }

      ::v-deep h3 {
        font-family: "Montserrat";
        font-size: 23px;
        color: #fff;
        margin: -3.5rem 0 0;
        padding: .5rem 1rem;
        text-transform: uppercase;
        position: relative;
        background: rgba(0,0,0,.5);
      }
      
      ::v-deep .sweet-content {
        @media (min-width: 1024px) {
          aside {
            float: right;

            img {
              width: 450px;
              margin-left: 20px;
            }
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          clear: right;
        }

        table {
          margin: 1rem 0;
          padding: 0;
          width: 100%;
          font-size: 1rem;

          tr {
            border-bottom: 1px solid #eee;

            td {
              vertical-align: middle;
              padding: .75rem;
            }

            th {
              vertical-align: middle;
              padding: .35rem .75rem;
              background: #d9f3ff;
            }
          }
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    background-size: contain;
    padding: 2rem 3%;

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    &.full-box-night-service {
      p {
        color: #fff;
        width: 50%;
      }
    }

    a {
      color: $title-color;
    }
  }

  .button {
    font-weight: normal;
    font-size: 1rem;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    background: #eee;
    color: #555;
    text-transform: uppercase;
  }
</style>
