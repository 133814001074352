<template>
  <div @click="$refs.modal.open()" class="detail-box-bike" :style="`background-color: ${bg}`">
    <Imgix :src="`${image}?w=640`" :alt="title" loading="lazy" />
    <h3>{{ title }}</h3>
    <slot></slot>
    <sweet-modal :title="title" ref="modal">
      <aside>
        <slot name="image"></slot>
      </aside>
      <slot></slot>
      <slot name="popis"></slot>
      <h4 v-if="pricelist.length">Cenník</h4>
      <table>
        <thead>
          <tr>
            <th v-for="(pricelistCell, index) in pricelist[0]" :key="`header${index}`">{{ header(index) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pricelistRow, index) in pricelist" :key="`row${index}`">
            <td v-for="(pricelistCell, jindex) in pricelistRow" :key="`row${index}cell${jindex}`">{{ pricelistCell | euro }}</td>
          </tr>
        </tbody>
      </table>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'DetailBoxBike',
  props: {
    title: String,
    image: String,
    pricelist: Array,
    bg: String
  },
  components: {
    SweetModal
  },
  filters: {
    euro: function (value) {
      if (!value)
        return ''
      const floatVal = parseFloat(value)
      if (!floatVal)
        return value
      return `${floatVal} €`
    }
  },
  methods: {
    header(index) {
      switch (index) {
        case 1: return "1 deň"
        case 2: return "1 - 4 hodiny"
        case 3: return "1 hodina"
        default:
          return "Doba požičania"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail-box-bike {
    cursor: pointer;
  }

  ::v-deep .sweet-modal {
    cursor: default;
    max-width: 850px;
  }

  ::v-deep .sweet-modal .sweet-title > h2 {
    color: #25b430;
  }

  ::v-deep .sweet-modal table td {
    text-align: center;
  }
</style>
